import "./Portfolio.css";
import romanceBookImage from "./assets/romance_book_image.jpg";
import climbingProjectImage from "./assets/climbing_shoe_project.JPEG";
import professionalPicture from "./assets/professional_pic_resized.jpg";

const climbingShoeProject = "https://climbingshoes.ericuu.com/"; // Climbing Shoe Project
// var bookshelfProject = "https://bookshelf-project.pages.dev/"; // Bookshelf Project

const PROJECTS = [
  {
    title: "Climbing Shoe Project",
    image: climbingProjectImage,
    imageAlt: "Eric Rock Climbing",
    link: climbingShoeProject,
  },
  {
    title: "Bookshelf Project",
    image: romanceBookImage,
    imageAlt: "Romance Section in Bookstore",
    link: climbingShoeProject,
  },
];

function PortfolioHeader() {
  return (
    <header className="portfolio-header">
      <h1>
        <b>Eric Wang</b>
      </h1>
      <p>
        <b>A website designed by Eric Wang.</b>
      </p>
    </header>
  );
}

function PortfolioGrid({ projects }) {
  const numCols = 4;
  const matrix = [];

  for (let i = 0, k = -1; i < projects.length; i++) {
    if (i % numCols === 0) {
      k++;
      matrix[k] = [];
    }
    matrix[k].push(<PortfolioItem key={i} project={projects[i]} />);
  }
  const transposedMatrix = matrix[0].map((_, colIndex) =>
    matrix.map((row) => row[colIndex])
  );

  return (
    <div className="grid-row">
      <div className="grid-column">{transposedMatrix[0]}</div>
      <div className="grid-column">{transposedMatrix[1]}</div>
      <div className="grid-column">{transposedMatrix[2]}</div>
      <div className="grid-column">{transposedMatrix[3]}</div>
    </div>
  );
}

function PortfolioItem({ project }) {
  return (
    <a
      className="grid-item"
      href={project.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={project.image}
        className="project-image"
        alt={project.imageAlt}
      ></img>
      <div className="project-title">
        <p>{project.title}</p>
      </div>
    </a>
  );
}

function PortfolioAboutMe() {
  return (
    <div className="standard-section">
      <div className="standard-wrapper">
        <div className="about-me-header-wrapper">
          <div className="about-me-header">
            <div className="about-me-image-wrapper">
              <img src={professionalPicture} alt="Eric Wang"></img>
            </div>
            <h3>Eric Wang</h3>
            <p>Software Developer</p>
          </div>
        </div>
        <div className="about-me-text">
          <p>
            Hello! I'm Eric Wang, a passionate developer with experience in building
            web applications using modern technologies. I enjoy creating clean and
            efficient code, and I'm always eager to learn new skills and improve my
            craft. In my free time, I love exploring new programming languages,
            contributing to open-source projects, and staying updated with the
            latest industry trends.
          </p>
        </div>
      </div>
    </div>
  );
}

function PortfolioContact() {
  return (
    <div className="contact-section">
      <div className="contact-wrapper">
        <div className="contact-header">
          <h2>Contact</h2>
        </div>
        <div className="contact-info">
          <div className="contact-form">
            <form>
              <div className="contact-input">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Your Name" required />
              </div>
              <div className="contact-input">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />
              </div>
              <div className="contact-input">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>
              <div className="contact-input">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
          <div className="vertical-line"></div>
          <div className="contact-social">
            <ul className="social-media-list">
              <li><i className="fab fa-facebook-f"></i> Facebook</li>
              <li><i className="fab fa-twitter"></i> Twitter</li>
              <li><i className="fab fa-linkedin-in"></i> LinkedIn</li>
              <li><i className="fab fa-github"></i> GitHub</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function Portfolio() {
  return (
    <div className="Portfolio">
      {/* Header Section */}
      <PortfolioHeader />

      {/* Grid Section */}
      <div className="grid-section">
        <div className="grid-wrapper">
          <PortfolioGrid projects={PROJECTS} />
        </div>
      </div>

      {/* About Me Section */}
      <PortfolioAboutMe />

      {/* Contact Me Section */}
      <PortfolioContact />
    </div>
  );
}

export default Portfolio;
